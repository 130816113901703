.logistic-image {
  overflow: hidden;
  height: 55vh;
  background-image: url("../../images/logistic.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(1.2);

  .logistic-content {
    text-align: center;
    color: white;
    display: flex;
    gap: 100px;
    margin-left: 15%;
    margin-right: 15%;
  }

  .logistic-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    height: 80px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    padding-bottom: 10px;
    font-size: 1.5rem;
  }

  .desc {
    padding-top: 10px;
    font-size: 0.9rem;
  }
}

.logistic-bottom-desc {
  padding-top: 4%;
  .logistic-desc {
    font-size: 2rem;
    color: #b38e1f;
  }
  .logistic-desc2 {
    font-size: 1rem;
    color: white;
    padding-bottom: 6%;
  }
}

.logistic-container {
  margin-left: 20%;
  margin-right: 20%;

  .desc-title-logistic {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title-logistic {
    color: #b38e1f;
    font-size: 2rem;
    display: flex;
    align-items: flex-start;
    text-align: start;
    justify-content: start;
  }
  .desc-logistic {
    color: white;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
  }

  .desc-button {
    padding-top: 20px;
    display: flex;
    color: white;
    justify-items: start;
    align-items: flex-start;
    text-align: start;
    .button {
      padding: 10px;
      border-radius: 10px;
      font-size: 1rem;
      background-color: #b38e1f;
      cursor: pointer;
    }

    .button:hover {
      background-color: black;
      border: 2px solid #b38e1f;
    }
  }

  .desc-button:hover {
  }
}

.line-divider {
  background: #8f7421;
  box-shadow: 0 0 30px 1.5px #8f7421;
  height: 2.5px;
  width: 100%;
  border: 1px solid #8f7421;
  filter: brightness(1.8);
}

@media only screen and (min-width: 300px) and (max-width: 575px) {
  .logistic-image {
    height: 100vh;
    .logistic-content {
      margin-left: 5%;
      margin-right: 5%;
      color: white;
      display: flex;
      gap: 0px;
    }
  }

  .logistic-bottom-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logistic-desc {
      font-size: 20px;
    }
    .logistic-desc2 {
      font-size: 13px;
    }
  }

  .logistic-container {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .desc-title-logistic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title-logistic {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
    .desc-logistic {
      text-align: center;
      font-size: 13px;
      padding-bottom: 10px;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
}

//phone landscape
@media only screen and (min-width: 768px) and (max-width: 992px) and (max-height: 500px) {
  .logistic-image {
    height: 100vh;
    .logistic-content {
      color: white;
      display: flex;
      gap: 0px;
    }
  }

  .logistic-bottom-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logistic-desc {
      font-size: 20px;
    }
    .logistic-desc2 {
      font-size: 13px;
    }
  }

  .logistic-container {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }

  .desc-title-logistic {
    .title-logistic {
      font-size: 20px;
      font-weight: 700;
    }
    .desc-logistic {
      font-size: 13px;
      padding-bottom: 10px;
    }
  }
}

//ipad potrait
@media only screen and (min-width: 768px) and (max-width: 992px) and (min-height: 1000px) {
  .logistic-image {
    height: 45vh;
    .logistic-content {
      color: white;
      display: flex;
      gap: 0px;
    }
    .title {
      font-size: 20px;
    }
    .desc {
      font-size: 15px;
    }
  }

  .logistic-bottom-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logistic-desc {
      font-size: 20px;
    }
    .logistic-desc2 {
      font-size: 13px;
    }
  }

  .logistic-container {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }

  .desc-title-logistic {
    .title-logistic {
      font-size: 20px;
      font-weight: 700;
    }
    .desc-logistic {
      font-size: 13px;
      padding-bottom: 10px;
    }
  }
}

//ipad landscape
@media only screen and (min-width: 992px) and (max-width: 1199px) and (max-height: 900px) {
  .logistic-image {
    height: 45vh;
    .logistic-content {
      color: white;
      display: flex;
      gap: 0px;
    }
    .title {
      font-size: 20px;
    }
    .desc {
      font-size: 15px;
    }
  }

  .logistic-bottom-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logistic-desc {
      font-size: 20px;
    }
    .logistic-desc2 {
      font-size: 13px;
    }
  }

  .logistic-container {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }

  .desc-title-logistic {
    .title-logistic {
      font-size: 20px;
      font-weight: 700;
    }
    .desc-logistic {
      font-size: 13px;
      padding-bottom: 10px;
    }
  }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1199px) and (min-height: 901px) {
  .logistic-image {
    height: 45vh;
    .logistic-content {
      color: white;
      display: flex;
      gap: 0px;
    }
    .title {
      font-size: 20px;
    }
    .desc {
      font-size: 15px;
    }
  }

  .logistic-bottom-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logistic-desc {
      font-size: 20px;
    }
    .logistic-desc2 {
      font-size: 13px;
    }
  }

  .logistic-container {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }

  .desc-title-logistic {
    .title-logistic {
      font-size: 20px;
      font-weight: 700;
    }
    .desc-logistic {
      font-size: 13px;
      padding-bottom: 10px;
    }
  }
}
