.footer-container {
  padding-top: 80px;
  padding-bottom: 100px;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;

  .logo-image {
    display: flex;
    justify-content: start;
    align-items: flex-start;

    img {
      max-width: 120px;
    }
  }

  .desc {
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: grey;
    font-size: 12px;
    .location-hour-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .location-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
  .desc-location {
    text-align: start;
    width: 80%;
  }
}
