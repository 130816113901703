.navbar-left {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: start;
  img {
    max-width: 120px;
  }
}

.navbar-right {
  color: white;
  line-height: 60px;
  padding: 10px 45px;

  .navbar-menu {
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-weight: 600;
    font-family: inherit;
    font-size: 14px;
  }

  .navbar-menu:hover {
    color: #b38e1f;
  }

  .facebook {
    font-size: 25px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.navbar-menu-small {
  display: flex;
  padding: 20px;
  justify-content: end;
  align-items: flex-end;

  .ant-btn {
  }
}

.dropdown-content-business {
  display: none;
  position: absolute;
  right: 0;
  background-color: #0a141a;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-business-image {
  display: block;

  img {
    width: 100%;
  }
}

.business:hover .dropdown-content-business {
  display: block;
}

.business-menu {
  color: white;
  text-transform: uppercase;
}

.business-menu:hover {
  color: #b38e1f;
}

.select-language {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  .ant-select {
    color: white;
  }

  img {
    width: 40%;
  }
  .ant-select-selection-item {
    div {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: black;
    border: none;
  }

  .ant-select-arrow {
    color: white;
  }
}
