.appointment-background {
  background-color: #313131;
}

.appointment-container {
  padding-top: 20px;
  margin-left: 20%;
  margin-right: 20%;

  .appointment-title {
    padding-top: 40px;
    color: white;
    font-size: 2rem;
    display: flex;
    justify-content: start;
  }

  .appointment-input {
    padding-top: 30px;

    .ant-input {
      background-color: #313131;
      border-radius: 10px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 10px;
      background-color: #313131;
    }
  }

  .ant-form-item-label > label {
    color: white;
  }

  .submit-button {
    display: flex;
    justify-content: start;
    button {
      filter: brightness(1.4);
      padding: 10px;
      width: 100%;
      font-size: 20px;
      border-radius: 10px;
      background-color: #c1981c;
      cursor: pointer;
    }
  }

  .submit-button:hover {
    filter: brightness(0.7);
  }
}
