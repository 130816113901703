.latest-news-info-container {
  .ant-card {
    background-color: #000000;
    border: none;
  }
  .latest-news-info-image {
    img {
      width: 100%;
      cursor: pointer;
    }
    img:hover {
      transform: scale(1.1);
      transition: all 0.5s;
    }
  }

  .LatestnewsInfoDesc {
    color: white;
    padding-bottom: 20px;
  }

  h1 {
    padding-top: 5px;
    font-family: "Righteous", sans-serif;
    color: rgba(255, 255, 255, 0.98) !important;
    text-transform: uppercase;
    font-size: 2.4rem;
    padding: 15px;
  }

  .back-to-list {
    background-color: #b38e1f;
    padding: 10px 15px 15px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: white;
    cursor: pointer;
  }

  .back-to-list:hover {
    transition: all 0.6s;
    background-color: grey;
    color: black;
  }

  .show-latest-info-image-container {
    .show-latest-info-image {
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 20%;
      height: 100%;
      bottom: 0;
      text-align: center;
      position: absolute;
      .ccarousel-image {
        padding-top: 5px;
        img {
          width: 100%;
          height: 550px;
        }
      }
    }
  }
}

.latest-news-info-container.active {
  background-color: rgb(255, 255, 255, 0.2);
  height: 100vh;
}

.fixed.inset-0.bg-black.bg-opacity-80.overflow-y-auto.h-full.w-full.z-50 {
  background-color: black !important;
}
