.build-tommorow {
  width: 100%;
  max-height: 65vh;
  position: relative;
  text-align: center;

  img {
    width: 100%;
    max-height: 65vh;
  }

  .text-container {
    position: absolute;
    top: 50%;
    color: white;
    margin-left: 20vw;
    margin-right: 20vw;
    display: flex;
    justify-content: start;
  }
  .text-build-tommorow {
    p {
      padding: 0;
      margin-bottom: 0;
    }
  }
  .build {
    font-size: 70px;
    font-weight: 800;
    text-transform: uppercase;
    text-align: start;
    line-height: 1;
  }

  .desc-build-tommorow {
    text-align: start;
    width: 25vw;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
}

@media only screen and (min-width: 300px) and (max-width: 575px) {
  .build-tommorow {
    width: 100%;
    height: 45vh;
    position: relative;
    text-align: center;

    img {
      width: 100%;
      height: 45vh;
    }
    .build {
      font-size: 20px;
      font-weight: 800;
      text-transform: uppercase;
      text-align: start;
      line-height: 1;
    }
    .desc-build-tommorow {
      text-align: start;
      width: 70vw;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .build-tommorow {
    width: 100%;
    height: 45vh;
    position: relative;
    text-align: center;

    img {
      width: 100%;
      height: 45vh;
    }
    .build {
      font-size: 20px;
      font-weight: 800;
      text-transform: uppercase;
      text-align: start;
      line-height: 1;
    }
    .desc-build-tommorow {
      text-align: start;
      width: 70vw;
    }
  }
}

//phone landscape
@media only screen and (min-width: 768px) and (max-width: 992px) and (max-height: 500px) {
  .build-tommorow {
    width: 100%;
    height: 80vh;
    position: relative;
    text-align: center;

    img {
      width: 100%;
      height: 80vh;
    }

    .text-container {
      top: 40%;
    }

    .build {
      font-size: 20px;
      font-weight: 800;
      text-transform: uppercase;
      text-align: start;
      line-height: 1;
    }
    .desc-build-tommorow {
      text-align: start;
      width: 50vw;
    }
  }
}

//ipad landscape
@media only screen and (min-width: 768px) and (max-width: 992px) and (min-height: 1000px) {
  .build-tommorow {
    width: 100%;
    height: 30vh;
    position: relative;
    text-align: center;

    img {
      width: 100%;
      height: 30vh;
    }

    .text-container {
      top: 40%;
    }

    .build {
      font-size: 20px;
      font-weight: 800;
      text-transform: uppercase;
      text-align: start;
      line-height: 1;
    }
    .desc-build-tommorow {
      text-align: start;
      width: 50vw;
    }
  }
}

//ipad potrait
@media only screen and (min-width: 992px) and (max-width: 1199px) and (max-height: 900px) {
  .build-tommorow {
    width: 100%;
    height: 80vh;
    position: relative;
    text-align: center;

    img {
      width: 100%;
      height: 80vh;
    }

    .build {
      font-size: 40px;
      font-weight: 800;
      text-transform: uppercase;
      text-align: start;
      line-height: 1;
    }
    .desc-build-tommorow {
      font-size: 15px;
      text-align: start;
      width: 50vw;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) and (min-height: 901px) {
  .build-tommorow {
    width: 100%;
    height: 50vh;
    position: relative;
    text-align: center;

    img {
      width: 100%;
      height: 50vh;
    }
    .text-container {
      top: 60%;
    }
    .build {
      font-size: 40px;
      font-weight: 800;
      text-transform: uppercase;
      text-align: start;
      line-height: 1;
    }
    .desc-build-tommorow {
      font-size: 20px;
      text-align: start;
      width: 50vw;
    }
  }
}
