.contact-image {
  overflow: hidden;
  height: 40vh;
  background-image: url("../../images/contactus.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(1.2);

  .contact-content {
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin-left: 15%;
    margin-right: 15%;
  }

  .contact-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    height: 80px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    padding-bottom: 10px;
    font-size: 1.5rem;
  }

  .desc {
    padding-top: 10px;
    font-size: 0.9rem;
  }
}
.whole-our-office {
  margin-left: 20%;
  margin-right: 20%;
  padding-top: 5%;
}
.our-office-container {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 2rem;
    color: #b38e1f;
    text-align: start;
  }

  .desc {
    padding-top: 30px;
    font-size: 1rem;
    text-align: start;
    color: white;
    width: 55%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  .desc2 {
    padding-top: 30px;
    font-size: 1rem;
    text-align: start;
    color: white;
    width: 40%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    padding-bottom: 5%;
  }
}

.line-divider {
  background: #8f7421;
  box-shadow: 0 0 30px 1.5px #8f7421;
  height: 2.5px;
  width: 100%;
  border: 1px solid #8f7421;
  filter: brightness(1.8);
}

@media only screen and (min-width: 300px) and (max-width: 575px) {
  .contact-image {
    height: 30vh;
  }
  .whole-our-office {
    height: 60vh;
  }

  .our-office-container {
    height: 5vh;
    .desc {
      width: 100%;
    }
    .desc2 {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
}

//phone landscape
@media only screen and (min-width: 768px) and (max-width: 992px) and (max-height: 500px) {
  .contact-image {
    height: 60vh;
  }
  .whole-our-office {
    height: 100vh;
    margin-left: 5%;
    margin-right: 5%;
  }
  .our-office-container {
    height: 5vh;
    .desc {
      width: 100%;
    }
    .desc2 {
      width: 100%;
    }
  }
}

//ipad potrait
@media only screen and (min-width: 768px) and (max-width: 992px) and (min-height: 1000px) {
  .whole-our-office {
    margin-left: 5%;
    margin-right: 5%;
  }
  .our-office-container {
    .desc {
      width: 100%;
    }
    .desc2 {
      width: 100%;
    }
  }
}

//ipad landscape
@media only screen and (min-width: 992px) and (max-width: 1199px) and (max-height: 900px) {
  .whole-our-office {
    margin-left: 5%;
    margin-right: 5%;
  }
  .our-office-container {
    .desc {
      width: 100%;
    }
    .desc2 {
      width: 100%;
    }
  }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1199px) and (min-height: 901px) {
}
