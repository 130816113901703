.introduce {
  padding-top: 20px;
  padding-bottom: 10px;
  overflow: hidden;
  .container {
    cursor: pointer;
  }
  img {
    width: 100%;
    cursor: pointer;
  }

  .desc-introduce {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    z-index: 1000;
    font-size: 2rem;
  }
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;

  img {
    width: auto;
  }
}

.container:hover .overlay {
  opacity: 1;
  background: rgba(0, 0, 0, 0.4);
}

.container:hover .desc-introduce {
  display: none;
}
