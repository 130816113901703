.charity-desc-container {
  padding-top: 5%;
  margin-right: 10%;
  margin-left: 10%;
  overflow: hidden;

  .charity-desc-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 80%;
      height: 80%;
      text-align: center;
    }
  }

  .charity-desc-desc {
    text-align: start;
    overflow: hidden;
    padding: 40px;
    .title {
      font-weight: 600;
      font-size: 1.2rem;
    }

    .title2 {
      color: #dcaf2c;
      font-weight: 700;
      padding-bottom: 10px;
    }

    .paragraph {
      line-height: 25px;
      font-weight: 600;
    }

    .hashtag-container {
      padding-top: 10px;
      .hashtag {
        font-weight: 600;
        color: #dcaf2c;
      }
    }
    .button-container {
      padding-top: 20px;

      .button-follow {
        padding: 5px;
        gap: 20px;
        display: flex;
        flex-direction: row;

        .icon {
          font-size: 1rem;
        }
      }

      button {
        color: white;
        background-color: #dcaf2c;
        border-radius: 5px;
        border: 1px solid #dcaf2c;
        cursor: pointer;
      }
    }
  }
}
